/**
 * Do not edit directly
 * Generated on Tue, 23 Apr 2024 20:24:55 GMT
 */

module.exports = {
  "blur": {
    "enabled": {
      "value": "blur(8px)",
      "filePath": "./design-tokens/blur.json",
      "isSource": true,
      "original": {
        "value": "blur(8px)"
      },
      "name": "blur-enabled",
      "attributes": {
        "category": "blur",
        "type": "enabled"
      },
      "path": [
        "blur",
        "enabled"
      ]
    },
    "disabled": {
      "value": "blur(0)",
      "filePath": "./design-tokens/blur.json",
      "isSource": true,
      "original": {
        "value": "blur(0)"
      },
      "name": "blur-disabled",
      "attributes": {
        "category": "blur",
        "type": "disabled"
      },
      "path": [
        "blur",
        "disabled"
      ]
    }
  },
  "border": {
    "radius": {
      "small": {
        "value": "3px",
        "filePath": "./design-tokens/border/radius.json",
        "isSource": true,
        "original": {
          "value": "3px"
        },
        "name": "border-radius-small",
        "attributes": {
          "category": "border",
          "type": "radius",
          "item": "small"
        },
        "path": [
          "border",
          "radius",
          "small"
        ]
      },
      "rounded": {
        "value": "100000px",
        "filePath": "./design-tokens/border/radius.json",
        "isSource": true,
        "original": {
          "value": "100000px"
        },
        "name": "border-radius-rounded",
        "attributes": {
          "category": "border",
          "type": "radius",
          "item": "rounded"
        },
        "path": [
          "border",
          "radius",
          "rounded"
        ]
      }
    }
  },
  "color": {
    "white": {
      "value": "#ffffff",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "#FFF"
      },
      "name": "color-white",
      "attributes": {
        "category": "color",
        "type": "white"
      },
      "path": [
        "color",
        "white"
      ]
    },
    "lightest-gray": {
      "value": "#f4f4f4",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "#F4F4F4"
      },
      "name": "color-lightest-gray",
      "attributes": {
        "category": "color",
        "type": "lightest-gray"
      },
      "path": [
        "color",
        "lightest-gray"
      ]
    },
    "lighter-gray": {
      "value": "#d5d5d5",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "#D5D5D5"
      },
      "name": "color-lighter-gray",
      "attributes": {
        "category": "color",
        "type": "lighter-gray"
      },
      "path": [
        "color",
        "lighter-gray"
      ]
    },
    "light-gray": {
      "value": "#ababab",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "#ABABAB"
      },
      "name": "color-light-gray",
      "attributes": {
        "category": "color",
        "type": "light-gray"
      },
      "path": [
        "color",
        "light-gray"
      ]
    },
    "gray": {
      "value": "#818181",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "#818181"
      },
      "name": "color-gray",
      "attributes": {
        "category": "color",
        "type": "gray"
      },
      "path": [
        "color",
        "gray"
      ]
    },
    "dark-gray": {
      "value": "#565656",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "#565656"
      },
      "name": "color-dark-gray",
      "attributes": {
        "category": "color",
        "type": "dark-gray"
      },
      "path": [
        "color",
        "dark-gray"
      ]
    },
    "darker-gray": {
      "value": "#2a2a2a",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "#2A2A2A"
      },
      "name": "color-darker-gray",
      "attributes": {
        "category": "color",
        "type": "darker-gray"
      },
      "path": [
        "color",
        "darker-gray"
      ]
    },
    "black": {
      "value": "#000000",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "#000"
      },
      "name": "color-black",
      "attributes": {
        "category": "color",
        "type": "black"
      },
      "path": [
        "color",
        "black"
      ]
    },
    "white-0": {
      "value": "rgba(255, 255, 255, 0)",
      "comment": "For animation purposes",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0)",
        "comment": "For animation purposes"
      },
      "name": "color-white-0",
      "attributes": {
        "category": "color",
        "type": "white-0"
      },
      "path": [
        "color",
        "white-0"
      ]
    },
    "white-03": {
      "value": "rgba(255, 255, 255, 0.03)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.03)"
      },
      "name": "color-white-03",
      "attributes": {
        "category": "color",
        "type": "white-03"
      },
      "path": [
        "color",
        "white-03"
      ]
    },
    "white-06": {
      "value": "rgba(255, 255, 255, 0.06)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.06)"
      },
      "name": "color-white-06",
      "attributes": {
        "category": "color",
        "type": "white-06"
      },
      "path": [
        "color",
        "white-06"
      ]
    },
    "white-10": {
      "value": "rgba(255, 255, 255, 0.1)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.1)"
      },
      "name": "color-white-10",
      "attributes": {
        "category": "color",
        "type": "white-10"
      },
      "path": [
        "color",
        "white-10"
      ]
    },
    "white-20": {
      "value": "rgba(255, 255, 255, 0.2)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.2)"
      },
      "name": "color-white-20",
      "attributes": {
        "category": "color",
        "type": "white-20"
      },
      "path": [
        "color",
        "white-20"
      ]
    },
    "white-30": {
      "value": "rgba(255, 255, 255, 0.3)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.3)"
      },
      "name": "color-white-30",
      "attributes": {
        "category": "color",
        "type": "white-30"
      },
      "path": [
        "color",
        "white-30"
      ]
    },
    "white-40": {
      "value": "rgba(255, 255, 255, 0.4)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.4)"
      },
      "name": "color-white-40",
      "attributes": {
        "category": "color",
        "type": "white-40"
      },
      "path": [
        "color",
        "white-40"
      ]
    },
    "white-50": {
      "value": "rgba(255, 255, 255, 0.5)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.5)"
      },
      "name": "color-white-50",
      "attributes": {
        "category": "color",
        "type": "white-50"
      },
      "path": [
        "color",
        "white-50"
      ]
    },
    "white-60": {
      "value": "rgba(255, 255, 255, 0.6)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.6)"
      },
      "name": "color-white-60",
      "attributes": {
        "category": "color",
        "type": "white-60"
      },
      "path": [
        "color",
        "white-60"
      ]
    },
    "white-70": {
      "value": "rgba(255, 255, 255, 0.7)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.7)"
      },
      "name": "color-white-70",
      "attributes": {
        "category": "color",
        "type": "white-70"
      },
      "path": [
        "color",
        "white-70"
      ]
    },
    "white-80": {
      "value": "rgba(255, 255, 255, 0.8)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.8)"
      },
      "name": "color-white-80",
      "attributes": {
        "category": "color",
        "type": "white-80"
      },
      "path": [
        "color",
        "white-80"
      ]
    },
    "white-90": {
      "value": "rgba(255, 255, 255, 0.9)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(255, 255, 255, 0.9)"
      },
      "name": "color-white-90",
      "attributes": {
        "category": "color",
        "type": "white-90"
      },
      "path": [
        "color",
        "white-90"
      ]
    },
    "black-0": {
      "value": "rgba(0, 0, 0, 0)",
      "comment": "For animation purposes",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0)",
        "comment": "For animation purposes"
      },
      "name": "color-black-0",
      "attributes": {
        "category": "color",
        "type": "black-0"
      },
      "path": [
        "color",
        "black-0"
      ]
    },
    "black-03": {
      "value": "rgba(0, 0, 0, 0.03)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.03)"
      },
      "name": "color-black-03",
      "attributes": {
        "category": "color",
        "type": "black-03"
      },
      "path": [
        "color",
        "black-03"
      ]
    },
    "black-06": {
      "value": "rgba(0, 0, 0, 0.06)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.06)"
      },
      "name": "color-black-06",
      "attributes": {
        "category": "color",
        "type": "black-06"
      },
      "path": [
        "color",
        "black-06"
      ]
    },
    "black-10": {
      "value": "rgba(0, 0, 0, 0.1)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.1)"
      },
      "name": "color-black-10",
      "attributes": {
        "category": "color",
        "type": "black-10"
      },
      "path": [
        "color",
        "black-10"
      ]
    },
    "black-20": {
      "value": "rgba(0, 0, 0, 0.2)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.2)"
      },
      "name": "color-black-20",
      "attributes": {
        "category": "color",
        "type": "black-20"
      },
      "path": [
        "color",
        "black-20"
      ]
    },
    "black-30": {
      "value": "rgba(0, 0, 0, 0.3)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.3)"
      },
      "name": "color-black-30",
      "attributes": {
        "category": "color",
        "type": "black-30"
      },
      "path": [
        "color",
        "black-30"
      ]
    },
    "black-40": {
      "value": "rgba(0, 0, 0, 0.4)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.4)"
      },
      "name": "color-black-40",
      "attributes": {
        "category": "color",
        "type": "black-40"
      },
      "path": [
        "color",
        "black-40"
      ]
    },
    "black-50": {
      "value": "rgba(0, 0, 0, 0.5)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.5)"
      },
      "name": "color-black-50",
      "attributes": {
        "category": "color",
        "type": "black-50"
      },
      "path": [
        "color",
        "black-50"
      ]
    },
    "black-60": {
      "value": "rgba(0, 0, 0, 0.6)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.6)"
      },
      "name": "color-black-60",
      "attributes": {
        "category": "color",
        "type": "black-60"
      },
      "path": [
        "color",
        "black-60"
      ]
    },
    "black-70": {
      "value": "rgba(0, 0, 0, 0.7)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.7)"
      },
      "name": "color-black-70",
      "attributes": {
        "category": "color",
        "type": "black-70"
      },
      "path": [
        "color",
        "black-70"
      ]
    },
    "black-80": {
      "value": "rgba(0, 0, 0, 0.8)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.8)"
      },
      "name": "color-black-80",
      "attributes": {
        "category": "color",
        "type": "black-80"
      },
      "path": [
        "color",
        "black-80"
      ]
    },
    "black-90": {
      "value": "rgba(0, 0, 0, 0.9)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "rgba(0,0,0, 0.9)"
      },
      "name": "color-black-90",
      "attributes": {
        "category": "color",
        "type": "black-90"
      },
      "path": [
        "color",
        "black-90"
      ]
    },
    "divider": {
      "value": "rgba(0, 0, 0, 0.1)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "{color.black-10.value}"
      },
      "name": "color-divider",
      "attributes": {
        "category": "color",
        "type": "divider"
      },
      "path": [
        "color",
        "divider"
      ]
    },
    "divider-dark": {
      "value": "rgba(0, 0, 0, 0.2)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "{color.black-20.value}"
      },
      "name": "color-divider-dark",
      "attributes": {
        "category": "color",
        "type": "divider-dark"
      },
      "path": [
        "color",
        "divider-dark"
      ]
    },
    "divider-light": {
      "value": "rgba(255, 255, 255, 0.1)",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "{color.white-10.value}"
      },
      "name": "color-divider-light",
      "attributes": {
        "category": "color",
        "type": "divider-light"
      },
      "path": [
        "color",
        "divider-light"
      ]
    },
    "muted": {
      "value": "#d5d5d5",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "{color.lighter-gray.value}"
      },
      "name": "color-muted",
      "attributes": {
        "category": "color",
        "type": "muted"
      },
      "path": [
        "color",
        "muted"
      ]
    },
    "very-muted": {
      "value": "#f4f4f4",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "{color.lightest-gray.value}"
      },
      "name": "color-very-muted",
      "attributes": {
        "category": "color",
        "type": "very-muted"
      },
      "path": [
        "color",
        "very-muted"
      ]
    },
    "disabled": {
      "value": "#ababab",
      "filePath": "./design-tokens/color.json",
      "isSource": true,
      "original": {
        "value": "{color.light-gray.value}"
      },
      "name": "color-disabled",
      "attributes": {
        "category": "color",
        "type": "disabled"
      },
      "path": [
        "color",
        "disabled"
      ]
    },
    "brand": {
      "primary": {
        "value": "#295de5",
        "filePath": "./design-tokens/color/brand.json",
        "isSource": true,
        "original": {
          "value": "#295DE5"
        },
        "name": "color-brand-primary",
        "attributes": {
          "category": "color",
          "type": "brand",
          "item": "primary"
        },
        "path": [
          "color",
          "brand",
          "primary"
        ]
      },
      "secondary": {
        "value": "#6422eb",
        "filePath": "./design-tokens/color/brand.json",
        "isSource": true,
        "original": {
          "value": "#6422EB"
        },
        "name": "color-brand-secondary",
        "attributes": {
          "category": "color",
          "type": "brand",
          "item": "secondary"
        },
        "path": [
          "color",
          "brand",
          "secondary"
        ]
      },
      "tertiary": {
        "value": "#501bbc",
        "filePath": "./design-tokens/color/brand.json",
        "isSource": true,
        "original": {
          "value": "501BBC"
        },
        "name": "color-brand-tertiary",
        "attributes": {
          "category": "color",
          "type": "brand",
          "item": "tertiary"
        },
        "path": [
          "color",
          "brand",
          "tertiary"
        ]
      }
    },
    "ui": {
      "primary": {
        "value": "#295de5",
        "filePath": "./design-tokens/color/ui.json",
        "isSource": true,
        "original": {
          "value": "{color.brand.primary.value}"
        },
        "name": "color-ui-primary",
        "attributes": {
          "category": "color",
          "type": "ui",
          "item": "primary"
        },
        "path": [
          "color",
          "ui",
          "primary"
        ]
      },
      "success": {
        "value": "#00a67f",
        "filePath": "./design-tokens/color/ui.json",
        "isSource": true,
        "original": {
          "value": "#00A67F"
        },
        "name": "color-ui-success",
        "attributes": {
          "category": "color",
          "type": "ui",
          "item": "success"
        },
        "path": [
          "color",
          "ui",
          "success"
        ]
      },
      "alert": {
        "value": "#d94011",
        "filePath": "./design-tokens/color/ui.json",
        "isSource": true,
        "original": {
          "value": "#D94011"
        },
        "name": "color-ui-alert",
        "attributes": {
          "category": "color",
          "type": "ui",
          "item": "alert"
        },
        "path": [
          "color",
          "ui",
          "alert"
        ]
      },
      "warning": {
        "value": "#d10034",
        "filePath": "./design-tokens/color/ui.json",
        "isSource": true,
        "original": {
          "value": "#D10034"
        },
        "name": "color-ui-warning",
        "attributes": {
          "category": "color",
          "type": "ui",
          "item": "warning"
        },
        "path": [
          "color",
          "ui",
          "warning"
        ]
      },
      "error": {
        "value": "#d10034",
        "filePath": "./design-tokens/color/ui.json",
        "isSource": true,
        "original": {
          "value": "#D10034"
        },
        "name": "color-ui-error",
        "attributes": {
          "category": "color",
          "type": "ui",
          "item": "error"
        },
        "path": [
          "color",
          "ui",
          "error"
        ]
      }
    }
  },
  "font": {
    "family": {
      "monospace": {
        "value": "'Montserrat', sans-serif",
        "filePath": "./design-tokens/font/family.json",
        "isSource": true,
        "original": {
          "value": "'Montserrat', sans-serif"
        },
        "name": "font-family-monospace",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "monospace"
        },
        "path": [
          "font",
          "family",
          "monospace"
        ]
      },
      "serif": {
        "value": "'Georgia', serif",
        "filePath": "./design-tokens/font/family.json",
        "isSource": true,
        "original": {
          "value": "'Georgia', serif"
        },
        "name": "font-family-serif",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "serif"
        },
        "path": [
          "font",
          "family",
          "serif"
        ]
      },
      "sans-serif": {
        "value": "'Roboto', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
        "filePath": "./design-tokens/font/family.json",
        "isSource": true,
        "original": {
          "value": "'Roboto', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif"
        },
        "name": "font-family-sans-serif",
        "attributes": {
          "category": "font",
          "type": "family",
          "item": "sans-serif"
        },
        "path": [
          "font",
          "family",
          "sans-serif"
        ]
      }
    },
    "size": {
      "tiny": {
        "value": "1rem",
        "comment": "the smallest size of the font",
        "filePath": "./design-tokens/font/size.json",
        "isSource": true,
        "original": {
          "value": "1rem",
          "comment": "the smallest size of the font"
        },
        "name": "font-size-tiny",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "tiny"
        },
        "path": [
          "font",
          "size",
          "tiny"
        ]
      },
      "smaller": {
        "value": "1.2rem",
        "comment": "a smaller font size",
        "filePath": "./design-tokens/font/size.json",
        "isSource": true,
        "original": {
          "value": "1.2rem",
          "comment": "a smaller font size"
        },
        "name": "font-size-smaller",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "smaller"
        },
        "path": [
          "font",
          "size",
          "smaller"
        ]
      },
      "small": {
        "value": "1.4rem",
        "comment": "the small size of the font",
        "filePath": "./design-tokens/font/size.json",
        "isSource": true,
        "original": {
          "value": "1.4rem",
          "comment": "the small size of the font"
        },
        "name": "font-size-small",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "small"
        },
        "path": [
          "font",
          "size",
          "small"
        ]
      },
      "medium": {
        "value": "2rem",
        "comment": "the medium size of the font",
        "filePath": "./design-tokens/font/size.json",
        "isSource": true,
        "original": {
          "value": "2rem",
          "comment": "the medium size of the font"
        },
        "name": "font-size-medium",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "medium"
        },
        "path": [
          "font",
          "size",
          "medium"
        ]
      },
      "large": {
        "value": "2.4rem",
        "comment": "the large size of the font",
        "filePath": "./design-tokens/font/size.json",
        "isSource": true,
        "original": {
          "value": "2.4rem",
          "comment": "the large size of the font"
        },
        "name": "font-size-large",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "large"
        },
        "path": [
          "font",
          "size",
          "large"
        ]
      },
      "larger": {
        "value": "3.6rem",
        "comment": "a larger font size",
        "filePath": "./design-tokens/font/size.json",
        "isSource": true,
        "original": {
          "value": "3.6rem",
          "comment": "a larger font size"
        },
        "name": "font-size-larger",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "larger"
        },
        "path": [
          "font",
          "size",
          "larger"
        ]
      },
      "largest": {
        "value": "4.2rem",
        "comment": "the largest size of the font",
        "filePath": "./design-tokens/font/size.json",
        "isSource": true,
        "original": {
          "value": "4.2rem",
          "comment": "the largest size of the font"
        },
        "name": "font-size-largest",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "largest"
        },
        "path": [
          "font",
          "size",
          "largest"
        ]
      },
      "base": {
        "value": "1.6rem",
        "comment": "the base size of the font",
        "filePath": "./design-tokens/font/size.json",
        "isSource": true,
        "original": {
          "value": "1.6rem",
          "comment": "the base size of the font"
        },
        "name": "font-size-base",
        "attributes": {
          "category": "font",
          "type": "size",
          "item": "base"
        },
        "path": [
          "font",
          "size",
          "base"
        ]
      }
    },
    "weight": {
      "light": {
        "value": "300",
        "filePath": "./design-tokens/font/weight.json",
        "isSource": true,
        "original": {
          "value": "300"
        },
        "name": "font-weight-light",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "light"
        },
        "path": [
          "font",
          "weight",
          "light"
        ]
      },
      "normal": {
        "value": "400",
        "filePath": "./design-tokens/font/weight.json",
        "isSource": true,
        "original": {
          "value": "400"
        },
        "name": "font-weight-normal",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "normal"
        },
        "path": [
          "font",
          "weight",
          "normal"
        ]
      },
      "bold": {
        "value": "700",
        "filePath": "./design-tokens/font/weight.json",
        "isSource": true,
        "original": {
          "value": "700"
        },
        "name": "font-weight-bold",
        "attributes": {
          "category": "font",
          "type": "weight",
          "item": "bold"
        },
        "path": [
          "font",
          "weight",
          "bold"
        ]
      }
    }
  },
  "grid": {
    "row": {
      "width": {
        "value": "1024px",
        "filePath": "./design-tokens/grid.json",
        "isSource": true,
        "original": {
          "value": "{screen.width.large.value}"
        },
        "name": "grid-row-width",
        "attributes": {
          "category": "grid",
          "type": "row",
          "item": "width"
        },
        "path": [
          "grid",
          "row",
          "width"
        ]
      }
    },
    "column": {
      "padding": {
        "value": "12px",
        "filePath": "./design-tokens/grid.json",
        "isSource": true,
        "original": {
          "value": "{spacing.small.value}"
        },
        "name": "grid-column-padding",
        "attributes": {
          "category": "grid",
          "type": "column",
          "item": "padding"
        },
        "path": [
          "grid",
          "column",
          "padding"
        ]
      }
    },
    "gutter": {
      "value": "12px * 2",
      "filePath": "./design-tokens/grid.json",
      "isSource": true,
      "original": {
        "value": "{spacing.small.value} * 2"
      },
      "name": "grid-gutter",
      "attributes": {
        "category": "grid",
        "type": "gutter"
      },
      "path": [
        "grid",
        "gutter"
      ]
    },
    "columns": {
      "value": "12",
      "filePath": "./design-tokens/grid.json",
      "isSource": true,
      "original": {
        "value": "12"
      },
      "name": "grid-columns",
      "attributes": {
        "category": "grid",
        "type": "columns"
      },
      "path": [
        "grid",
        "columns"
      ]
    }
  },
  "icon": {
    "size": {
      "tiny": {
        "value": "8px",
        "filePath": "./design-tokens/icon/size.json",
        "isSource": true,
        "original": {
          "value": "8px"
        },
        "name": "icon-size-tiny",
        "attributes": {
          "category": "icon",
          "type": "size",
          "item": "tiny"
        },
        "path": [
          "icon",
          "size",
          "tiny"
        ]
      },
      "small": {
        "value": "16px",
        "filePath": "./design-tokens/icon/size.json",
        "isSource": true,
        "original": {
          "value": "16px"
        },
        "name": "icon-size-small",
        "attributes": {
          "category": "icon",
          "type": "size",
          "item": "small"
        },
        "path": [
          "icon",
          "size",
          "small"
        ]
      },
      "medium": {
        "value": "24px",
        "filePath": "./design-tokens/icon/size.json",
        "isSource": true,
        "original": {
          "value": "24px"
        },
        "name": "icon-size-medium",
        "attributes": {
          "category": "icon",
          "type": "size",
          "item": "medium"
        },
        "path": [
          "icon",
          "size",
          "medium"
        ]
      },
      "large": {
        "value": "48px",
        "filePath": "./design-tokens/icon/size.json",
        "isSource": true,
        "original": {
          "value": "48px"
        },
        "name": "icon-size-large",
        "attributes": {
          "category": "icon",
          "type": "size",
          "item": "large"
        },
        "path": [
          "icon",
          "size",
          "large"
        ]
      },
      "larger": {
        "value": "64px",
        "filePath": "./design-tokens/icon/size.json",
        "isSource": true,
        "original": {
          "value": "64px"
        },
        "name": "icon-size-larger",
        "attributes": {
          "category": "icon",
          "type": "size",
          "item": "larger"
        },
        "path": [
          "icon",
          "size",
          "larger"
        ]
      },
      "largest": {
        "value": "128px",
        "filePath": "./design-tokens/icon/size.json",
        "isSource": true,
        "original": {
          "value": "128px"
        },
        "name": "icon-size-largest",
        "attributes": {
          "category": "icon",
          "type": "size",
          "item": "largest"
        },
        "path": [
          "icon",
          "size",
          "largest"
        ]
      },
      "base": {
        "value": "24px",
        "filePath": "./design-tokens/icon/size.json",
        "isSource": true,
        "original": {
          "value": "{icon.size.medium.value}"
        },
        "name": "icon-size-base",
        "attributes": {
          "category": "icon",
          "type": "size",
          "item": "base"
        },
        "path": [
          "icon",
          "size",
          "base"
        ]
      }
    }
  },
  "line-height": {
    "global": {
      "value": "1.5",
      "filePath": "./design-tokens/line-height.json",
      "isSource": true,
      "original": {
        "value": "1.5"
      },
      "name": "line-height-global",
      "attributes": {
        "category": "line-height",
        "type": "global"
      },
      "path": [
        "line-height",
        "global"
      ]
    },
    "base": {
      "value": "2.4rem",
      "filePath": "./design-tokens/line-height.json",
      "isSource": true,
      "original": {
        "value": "2.4rem"
      },
      "name": "line-height-base",
      "attributes": {
        "category": "line-height",
        "type": "base"
      },
      "path": [
        "line-height",
        "base"
      ]
    },
    "header": {
      "value": "1.1",
      "filePath": "./design-tokens/line-height.json",
      "isSource": true,
      "original": {
        "value": "1.1"
      },
      "name": "line-height-header",
      "attributes": {
        "category": "line-height",
        "type": "header"
      },
      "path": [
        "line-height",
        "header"
      ]
    }
  },
  "opacity": {
    "disabled": {
      "value": "0.35",
      "filePath": "./design-tokens/opacity.json",
      "isSource": true,
      "original": {
        "value": "0.35"
      },
      "name": "opacity-disabled",
      "attributes": {
        "category": "opacity",
        "type": "disabled"
      },
      "path": [
        "opacity",
        "disabled"
      ]
    }
  },
  "screen": {
    "width": {
      "full": {
        "value": "100%",
        "filePath": "./design-tokens/screen/width.json",
        "isSource": true,
        "original": {
          "value": "100%"
        },
        "name": "screen-width-full",
        "attributes": {
          "category": "screen",
          "type": "width",
          "item": "full"
        },
        "path": [
          "screen",
          "width",
          "full"
        ]
      },
      "small": {
        "value": "0",
        "filePath": "./design-tokens/screen/width.json",
        "isSource": true,
        "original": {
          "value": "0"
        },
        "name": "screen-width-small",
        "attributes": {
          "category": "screen",
          "type": "width",
          "item": "small"
        },
        "path": [
          "screen",
          "width",
          "small"
        ]
      },
      "medium": {
        "value": "768px",
        "filePath": "./design-tokens/screen/width.json",
        "isSource": true,
        "original": {
          "value": "768px"
        },
        "name": "screen-width-medium",
        "attributes": {
          "category": "screen",
          "type": "width",
          "item": "medium"
        },
        "path": [
          "screen",
          "width",
          "medium"
        ]
      },
      "large": {
        "value": "1024px",
        "filePath": "./design-tokens/screen/width.json",
        "isSource": true,
        "original": {
          "value": "1024px"
        },
        "name": "screen-width-large",
        "attributes": {
          "category": "screen",
          "type": "width",
          "item": "large"
        },
        "path": [
          "screen",
          "width",
          "large"
        ]
      },
      "xlarge": {
        "value": "1200px",
        "filePath": "./design-tokens/screen/width.json",
        "isSource": true,
        "original": {
          "value": "1200px"
        },
        "name": "screen-width-xlarge",
        "attributes": {
          "category": "screen",
          "type": "width",
          "item": "xlarge"
        },
        "path": [
          "screen",
          "width",
          "xlarge"
        ]
      },
      "xxlarge": {
        "value": "1440px",
        "filePath": "./design-tokens/screen/width.json",
        "isSource": true,
        "original": {
          "value": "1440px"
        },
        "name": "screen-width-xxlarge",
        "attributes": {
          "category": "screen",
          "type": "width",
          "item": "xxlarge"
        },
        "path": [
          "screen",
          "width",
          "xxlarge"
        ]
      },
      "nav": {
        "value": "768px",
        "filePath": "./design-tokens/screen/width.json",
        "isSource": true,
        "original": {
          "value": "{screen.width.medium.value}"
        },
        "name": "screen-width-nav",
        "attributes": {
          "category": "screen",
          "type": "width",
          "item": "nav"
        },
        "path": [
          "screen",
          "width",
          "nav"
        ]
      }
    }
  },
  "box": {
    "shadow": {
      "default": {
        "value": "0 1px 4px 0 rgba(0, 0, 0, 0.3)",
        "filePath": "./design-tokens/shadow.json",
        "isSource": true,
        "original": {
          "value": "0 1px 4px 0 {color.black-30.value}"
        },
        "name": "box-shadow-default",
        "attributes": {
          "category": "box",
          "type": "shadow",
          "item": "default"
        },
        "path": [
          "box",
          "shadow",
          "default"
        ]
      },
      "hover": {
        "value": "0 2px 6px 0 rgba(0, 0, 0, 0.5)",
        "filePath": "./design-tokens/shadow.json",
        "isSource": true,
        "original": {
          "value": "0 2px 6px 0 {color.black-50.value}"
        },
        "name": "box-shadow-hover",
        "attributes": {
          "category": "box",
          "type": "shadow",
          "item": "hover"
        },
        "path": [
          "box",
          "shadow",
          "hover"
        ]
      },
      "active": {
        "value": "0 1px 2px 0 rgba(0, 0, 0, 0.3)",
        "filePath": "./design-tokens/shadow.json",
        "isSource": true,
        "original": {
          "value": "0 1px 2px 0 {color.black-30.value}"
        },
        "name": "box-shadow-active",
        "attributes": {
          "category": "box",
          "type": "shadow",
          "item": "active"
        },
        "path": [
          "box",
          "shadow",
          "active"
        ]
      }
    }
  },
  "spacing": {
    "tiny": {
      "value": "6px",
      "filePath": "./design-tokens/spacing.json",
      "isSource": true,
      "original": {
        "value": "6px"
      },
      "name": "spacing-tiny",
      "attributes": {
        "category": "spacing",
        "type": "tiny"
      },
      "path": [
        "spacing",
        "tiny"
      ]
    },
    "small": {
      "value": "12px",
      "filePath": "./design-tokens/spacing.json",
      "isSource": true,
      "original": {
        "value": "12px"
      },
      "name": "spacing-small",
      "attributes": {
        "category": "spacing",
        "type": "small"
      },
      "path": [
        "spacing",
        "small"
      ]
    },
    "medium": {
      "value": "24px",
      "filePath": "./design-tokens/spacing.json",
      "isSource": true,
      "original": {
        "value": "24px"
      },
      "name": "spacing-medium",
      "attributes": {
        "category": "spacing",
        "type": "medium"
      },
      "path": [
        "spacing",
        "medium"
      ]
    },
    "large": {
      "value": "48px",
      "filePath": "./design-tokens/spacing.json",
      "isSource": true,
      "original": {
        "value": "48px"
      },
      "name": "spacing-large",
      "attributes": {
        "category": "spacing",
        "type": "large"
      },
      "path": [
        "spacing",
        "large"
      ]
    },
    "larger": {
      "value": "72px",
      "filePath": "./design-tokens/spacing.json",
      "isSource": true,
      "original": {
        "value": "72px"
      },
      "name": "spacing-larger",
      "attributes": {
        "category": "spacing",
        "type": "larger"
      },
      "path": [
        "spacing",
        "larger"
      ]
    },
    "base": {
      "value": "24px",
      "filePath": "./design-tokens/spacing.json",
      "isSource": true,
      "original": {
        "value": "{spacing.medium.value}"
      },
      "name": "spacing-base",
      "attributes": {
        "category": "spacing",
        "type": "base"
      },
      "path": [
        "spacing",
        "base"
      ]
    }
  },
  "z-index": {
    "above-everything": {
      "value": "1000",
      "filePath": "./design-tokens/z-index.json",
      "isSource": true,
      "original": {
        "value": "1000"
      },
      "name": "z-index-above-everything",
      "attributes": {
        "category": "z-index",
        "type": "above-everything"
      },
      "path": [
        "z-index",
        "above-everything"
      ]
    },
    "above-most": {
      "value": "10",
      "filePath": "./design-tokens/z-index.json",
      "isSource": true,
      "original": {
        "value": "10"
      },
      "name": "z-index-above-most",
      "attributes": {
        "category": "z-index",
        "type": "above-most"
      },
      "path": [
        "z-index",
        "above-most"
      ]
    },
    "above": {
      "value": "1",
      "filePath": "./design-tokens/z-index.json",
      "isSource": true,
      "original": {
        "value": "1"
      },
      "name": "z-index-above",
      "attributes": {
        "category": "z-index",
        "type": "above"
      },
      "path": [
        "z-index",
        "above"
      ]
    },
    "default": {
      "value": "0",
      "filePath": "./design-tokens/z-index.json",
      "isSource": true,
      "original": {
        "value": "0"
      },
      "name": "z-index-default",
      "attributes": {
        "category": "z-index",
        "type": "default"
      },
      "path": [
        "z-index",
        "default"
      ]
    },
    "below": {
      "value": "-1",
      "filePath": "./design-tokens/z-index.json",
      "isSource": true,
      "original": {
        "value": "-1"
      },
      "name": "z-index-below",
      "attributes": {
        "category": "z-index",
        "type": "below"
      },
      "path": [
        "z-index",
        "below"
      ]
    },
    "below-most": {
      "value": "-10",
      "filePath": "./design-tokens/z-index.json",
      "isSource": true,
      "original": {
        "value": "-10"
      },
      "name": "z-index-below-most",
      "attributes": {
        "category": "z-index",
        "type": "below-most"
      },
      "path": [
        "z-index",
        "below-most"
      ]
    },
    "below-everything": {
      "value": "-1000",
      "filePath": "./design-tokens/z-index.json",
      "isSource": true,
      "original": {
        "value": "-1000"
      },
      "name": "z-index-below-everything",
      "attributes": {
        "category": "z-index",
        "type": "below-everything"
      },
      "path": [
        "z-index",
        "below-everything"
      ]
    }
  }
};